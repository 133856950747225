import React from 'react';
import './footer.scss';
import viLang from '../../languages/vi';

export default class FooterView extends React.Component {
    render() {
        return (
            <footer className="footer-container">
                <div className="info">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="name">
                                    <img src="../logo.png" height="60"/>
                                    <h3>Sở kế hoạch đầu tư <br/>Tỉnh Vĩnh Phúc</h3>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="detail">
                                    <p>
                                        <svg width="14" height="14" viewBox="0 0 10 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5 0C2.23571 0 0 2.191 0 4.9C0 8.575 5 14 5 14C5 14 10 8.575 10 4.9C10 2.191 7.76429 0 5 0ZM5 6.65C4.01429 6.65 3.21429 5.866 3.21429 4.9C3.21429 3.934 4.01429 3.15 5 3.15C5.98571 3.15 6.78571 3.934 6.78571 4.9C6.78571 5.866 5.98571 6.65 5 6.65Z"
                                                fill="#F5F5F5"/>
                                        </svg>
                                        {viLang.footer_address}
                                    </p>
                                    <p>
                                        <svg width="14" height="14" aria-hidden="true" focusable="false" data-prefix="fas"
                                             data-icon="phone-alt" className="svg-inline--fa fa-phone-alt fa-w-16"
                                             role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                  d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
                                        </svg>
                                        {viLang.footer_hotline}
                                    </p>
                                    <p>
                                        <svg width="14" height="14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
                                             className="svg-inline--fa fa-envelope fa-w-16" role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                  d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path>
                                        </svg>
                                        Email: {viLang.footer_email}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        {viLang.footer_copyright}
                    </div>
                </div>
            </footer>
        )
    }
}